import { usePage } from '@inertiajs/react';

import { encodeUrl } from '../Shared/helpers';
import { PageProps } from './types';

function path(...segments: (string | number)[]) {
  return ['', ...segments].join('/');
}

function query(path: string, params: object) {
  const query = encodeUrl(params);

  return query ? `${path}?${query}` : path;
}

export function useRouter() {
  const { props: { charity }, url } = usePage<PageProps>();
  const defaultCharityId = charity?.id || '';

  const routes = {
    // Dashboard
    'dashoard_page': () => '/me',

    // Platform
    'select_charity_page': (params: { charityId?: string | null, eventId?: string | null }) => query(path('create'), { charity_id: params.charityId, event_id: params.eventId }),

    // Charity
    'charity_page': (charityId = defaultCharityId) => path(charityId),

    // Project
    'project_page': (projectId: string) => path(defaultCharityId, 'projects', projectId),

    // Event
    'event_page': (eventId: string) => `/events/${eventId}`,

    // Campaign
    'campaign_page': (campaignId: string) => path(defaultCharityId, campaignId),
    'create_campaign_page': (charityId = defaultCharityId) => path(charityId, 'create'),
    'campaign_products_page': (campaignId: string) => path(defaultCharityId, campaignId, 'products'),
    'edit_campaign_page': (campaignId: string) => path(defaultCharityId, campaignId, 'edit'),
    'edit_campaign': (campaignId: string) => path(defaultCharityId, campaignId, 'edit'),
    'join_team': (teamId: string) => path(defaultCharityId, teamId, 'join'),
    'leave_team': (campaignId: string) => path(defaultCharityId, campaignId, 'leave_team'),
    'delete_campaign': (campaignId: string) => path(defaultCharityId, campaignId, 'delete'),
    'add_product': (campaignId: string) => path(defaultCharityId, campaignId, 'products'),
    'edit_product': (campaignId: string, productId: string) => path(defaultCharityId, campaignId, 'products', productId, 'edit'),
    'toggle_product': (campaignId: string, productId: string) => path(defaultCharityId, campaignId, 'products', productId, 'toggle'),
    'delete_product': (campaignId: string, productId: string) => path(defaultCharityId, campaignId, 'products', productId, 'delete'),
    'donation_page': (campaignId: string) => path(defaultCharityId, campaignId, 'donate'),
    'donate': (campaignId: string) => path(defaultCharityId, campaignId, 'donate'),
    'add_campaign': () => path(defaultCharityId, 'campaigns'),

    // Checkout
    'complete_test_checkout': (donationId: string) => path(defaultCharityId, 'test-checkout', donationId, 'complete'),

    // Donation
    'edit_billing_info': (donationId: string, token: string) => path(defaultCharityId, 'donations', donationId, token, 'edit_billing_info'),

    // Misc
    'help_page': () => path(defaultCharityId, 'help'),
    'privacy_page': () => path(defaultCharityId, 'privacy'),

    // Auth
    'login_page': () => '/login',
    'logout': () => '/logout',

    // Back-end
    'backend_page': (charityId: string) => path('b', charityId),
  };

  const isActive = (route: string, exact = false) => {
    const path = url.split('?')[0];

    if (exact) {
      return path === route;
    }

    return path.startsWith(route);
  };

  return {
    routes,
    isActive,
  };
}
